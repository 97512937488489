<template>
  <div
    class="my-1 px-2 text-gray-400 select-none"
    :class="classSize"
  >
    <div class="flex justify-between pb-1 ">
      <div class="left-side flex-auto truncate pr-2">
        <div class="px-1 py-1 flex items-center">
          <div class="italic flex flex-col">
            <span>{{ hiddenItemCount }} item<template v-if="hiddenItemCount > 1">s</template> hidden</span>
            <span>Only showing <template v-for="type,index in restrictedActionTypes">{{ type }}<template v-if="restrictedActionTypes.length >= index">, </template></template> folder items. <br></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    size: {
      type: String,
      default: 'sm'
    },
    hiddenItemCount: {
      type: Number,
      default: 0
    },

    restrictedActionTypes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    classSize () {
      return 'text-' + this.size
    }
  }
}

</script>
